function patchStorageClear() {
  const style = 'color: red; font-weight: bold;';
  const warningMessage =
    "Please note, that clearing the browser's local or session storage will impact the HDP App Shell as well as all other applications.";
  const originalLocalStorageClear = localStorage.clear;
  const originalSessionStorageClear = sessionStorage.clear;

  localStorage.clear = () => {
    console.warn(`%c${warningMessage}`, style);
    originalLocalStorageClear.call(localStorage);
  };

  sessionStorage.clear = () => {
    console.warn(`%c${warningMessage}`, style);
    originalSessionStorageClear.call(sessionStorage);
  };
}

export const patchBrowserApi = (): void => {
  patchStorageClear();
};
