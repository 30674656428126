import { BehaviorSubject } from 'rxjs';

import { getSessionData } from './auth';
import { getPublicPath } from './environment';
import { setupI18next } from './localization/i18n';
import { createLanguageChangeObservers } from './localization/language-change-observers';
import {
  createLanguageLoader,
  DEFAULT_LANGUAGE,
  DEFAULT_LOCALE,
  getBrowserLanguageFromAvailableLanguages,
  getBrowserLocaleFromAvailableLocales,
  getUserLanguageFromAvailableLanguages,
  getUserLocaleFromAvailableLocales,
} from './localization/language-resolving';
import { getLegalLanguageFromConfig } from './localization/legal-language';
import type { HdpApp, LanguageCode, RuntimeConfig } from './types';

export function createApp(config: RuntimeConfig): HdpApp {
  const basePath = getPublicPath();
  const country = getSessionData()?.country || 'default';

  const languageProvider = setupI18next(
    createLanguageLoader(config.dynamicTranslations),
    DEFAULT_LANGUAGE
  );

  const getBrowserLocale = () => getBrowserLocaleFromAvailableLocales(config.availableLocales);
  const getUserLocale = () => getUserLocaleFromAvailableLocales(config.availableLocales);

  const getBrowserLanguage = () =>
    getBrowserLanguageFromAvailableLanguages(config.availableLanguages);
  const getUserLanguage = () => getUserLanguageFromAvailableLanguages(config.availableLanguages);
  const getLegalLanguage = (country: string) =>
    getLegalLanguageFromConfig(country, config.legalLanguages, getUserLanguage());

  const currentLanguage$ = new BehaviorSubject<string>(getUserLanguage());

  const app: HdpApp = {
    currentLanguage$: currentLanguage$.asObservable(),
    basePath,
    config,
    title: 'HDP App Shell',
    defaultLocale: DEFAULT_LOCALE,
    defaultLanguage: DEFAULT_LANGUAGE,
    country,
    languageProvider,
    getLegalLanguage,
    getUserLocale,
    getUserLanguage,
    getBrowserLocale,
    getBrowserLanguage,
  };

  createLanguageChangeObservers(app, (language: LanguageCode) => currentLanguage$.next(language));

  return app;
}
