import './set-zui-session-storage-item'; // must be loaded first, keep this on top
import '@zeiss/zui-icons';
import '@zeiss/zui-v/major';

import { registerSessionSync } from './auth';
import { getPublicPath } from './environment';
import { setupApp } from './setup';
import type { AppConfig, UserDevice } from './types';
import { registerTenantSync } from './utils/multi-tab-tenant-switch';

const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const basePath = getPublicPath();

    await navigator.serviceWorker.register(`${basePath}service-worker.js`, {
      scope: basePath,
    });
  }
};

// We only want to run the app when we retrieved the config and are sure
// that the browser is compatible / supported.
// This event is picked up / handled in "browser-check.js"
window.dispatchEvent(
  new CustomEvent('queue-hdp-setup', {
    detail(config: AppConfig, deviceType: UserDevice) {
      (document.getElementById('app') as HTMLElement).style.display = 'flex';

      registerServiceWorker();
      registerSessionSync();
      registerTenantSync();

      setupApp(config, deviceType);
    },
  })
);
