import { match } from '@formatjs/intl-localematcher';

import { getPublicPath } from '../environment';
import type { LanguageLoader } from '../plugins/localization/plugin';
import type { LanguageCode, LocaleCode } from '../types';
import { getUserDefinedLanguage } from './user-defined-language';

export function createLanguageLoader(dynamicTranslations: boolean): LanguageLoader {
  return async (language) => {
    if (!dynamicTranslations) {
      return loadBundledTranslations(language);
    }

    try {
      return await loadTranslationsViaHttp(language);
    } catch (error) {
      return loadBundledTranslations(language);
    }
  };
}

async function loadTranslationsViaHttp(language: string): Promise<Record<string, string>> {
  const languageFile = new URL(
    `assets/translations/${language}.json`,
    `${location.origin}${getPublicPath()}`
  );

  const response = await fetch(languageFile.toString());

  if (!response.ok) {
    throw new Error('Language files could not be loaded!');
  }

  return response.json();
}

function loadBundledTranslations(language: string): Promise<Record<string, string>> {
  return import(`../assets/translations/${language}.json`);
}

export function getBrowserLocaleFromAvailableLocales(
  availableLocales: Array<LocaleCode>
): LocaleCode {
  return match([navigator.language], availableLocales, DEFAULT_LOCALE);
}

export function getBrowserLanguageFromAvailableLanguages(
  availableLanguages: Array<LanguageCode>
): LanguageCode {
  return match([navigator.language], availableLanguages, DEFAULT_LANGUAGE);
}

export function getUserLocaleFromAvailableLocales(availableLocales: Array<LocaleCode>): LocaleCode {
  const userDefinedLanguage = getUserDefinedLanguage();

  if (!userDefinedLanguage) {
    return getBrowserLocaleFromAvailableLocales(availableLocales);
  }

  return match([userDefinedLanguage], availableLocales, DEFAULT_LOCALE);
}

export function getUserLanguageFromAvailableLanguages(
  availableLanguages: Array<LanguageCode>
): LanguageCode {
  const userDefinedLanguage = getUserDefinedLanguage();

  if (!userDefinedLanguage) {
    return getBrowserLanguageFromAvailableLanguages(availableLanguages);
  }

  return match([userDefinedLanguage], availableLanguages, DEFAULT_LANGUAGE);
}

/**
 * @deprecated in the favor of `DEFAULT_LANGUAGE`
 */
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_LANGUAGE = 'en-US';
export const DEFAULT_LEGAL_LANGUAGE = DEFAULT_LANGUAGE;
